@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700");

/*reset all css*/

a,
a:focus {
    outline: none;
}


/*group of btns*/

.sign-in-btn {
    margin-top: 10px;
}


/*header top bar css*/

.navbar-top .container {
    position: relative;
}

.login-register {
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
    -ms-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
    -o-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.4);
    padding: 25px 30px 30px;
    position: absolute;
    right: 0;
    top: 67px;
    width: 400px;
}

.login-register-top-bar {
    float: left;
    width: 100%;
}

.login-register-top-bar > h2 {
    margin: 0 0 15px;
    text-transform: capitalize !important;
}

.login-form-elements {
    float: left;
    width: 100%;
}

.login-form-elements .form-group label {
    text-transform: capitalize !important;
}

.login-form-elements .form-group button {
    width: 100%;
}

.forgot-password {
    float: left;
    font-size: 85%;
    text-align: center;
    text-transform: capitalize !important;
    width: 100%;
}

.login-register .glyphicon.glyphicon-triangle-top {
    color: #ccc;
    position: absolute;
    right: 64px;
    top: -10px;
}


/* .hideContains span {
    display: none;
} */

.logo-img {
    margin: auto;
    width: 100%;
    text-align: center;
}

.middle-box {
    padding-top: 0px !important;
}

.red {
    color: red !important;
}

.green {
    background-color: #09375f !important;
    color: #ffffff !important;
    font-size: 14Px !important;
    width: 20% !important;
}

.passwordBox {
    padding: 0px 20px 20px 20px !important;
}

.login-page {
    background-color: #ffffff !important;
}

.forgotpassword-page {
    background-color: #ffffff !important;
}

.resetpassword-page {
    background-color: #ffffff !important;
}

.capitalize {
    text-transform: capitalize !important;
}


/*Loader CSS*/

.preview {
    font-size: 13px;
    font-weight: bold;
    line-height: 17px;
    line-height: 500px;
}

#loading {
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    position: fixed;
    display: block;
    opacity: 0.7;
    filter: alpha(opacity=80);
    z-index: 99;
    text-align: center;
}

#loading > img {
    margin-top: 11em;
}

.pause-loader {
    animation-play-state: paused !important;
    visibility: hidden;
}

.play-loader {
    animation-play-state: running !important;
    visibility: visible;
}

.attorneytyppeahead ul {
    min-height: 50px !important;
    overflow: auto !important;
    max-height: 450px !important;
}

.error-page {
    background-color: #ffffff !important;
}


/* add * for required fields */

label.require:after {
    content: ' *';
    color: #ef4747;
}


/* .form-control{text-transform: capitalize;} */


/* Add Edit Firm information page change tab width */

.firm-tabs > ul > li {
    width: 16% !important;
}
.diary-tabs > ul > li {
    width: 18% !important;
}

ul.notes li div a.edit {
    position: absolute;
    right: 27px;
    bottom: 10px;
    color: inherit;
}

.issue-info {
    width: 25% !important;
}

.attorney-info {
    width: 12% !important;
}

.findCase-typeahead > ul {
    top: 34px !important;
    left: 15px !important;
    height: auto !important;
    max-height: 500px !important;
    overflow-y: scroll !important;
    /*width: 95% !important;*/
    width: 170% !important;
}

.attorneyfirm-typeahead > ul {
    height: auto !important;
    max-height: 300px !important;
    overflow-y: scroll !important;
    width: 100% !important;
}

.claimrep-typeahead > ul {
    height: auto !important;
    max-height: 200px !important;
    overflow-y: scroll !important;
}

.claimrep-typeahead .chosen-container {
    vertical-align: baseline !important;
}

input {
    text-transform: capitalize !important;
}

textarea {
    text-transform: capitalize !important;
}
.attorney_firm  input
{
    width: 700px !important;
    margin-right: 130px !important;
}
.ocopy {
    margin-right:-30px
    /* margin-right:-70px */
}
input.custombox {
    margin-top:10px;
}
input.customhover {
    /* border-color: #26b67c !important; */
    border : 2px solid #26b67c !important;
}
input.lightgreenbackground {
    background: #90EE90;
}
select.lightgreenbackground{
    background: #90EE90;
}
input.lightyellowbackground {

    background: #FFFF99;
}
select.lightyellowbackground{
    background: #FFFF99;
}
.container-lightbackground {
    background-color:#e7eaec !important;
}
.witness-info-label {
    line-height: 36px;
}
textarea.resize-box {
    resize: vertical;
}

input.custombox-radio {
    /* margin-top:10px; */
    margin-top:12px;
}
input.customboxT-radio {
    /* margin-top:10px; */
    margin-top:2px;
}
.custom-info-label {
    margin-bottom: 16px;
}

textarea.note-control{
    background-color: #ffc4cd;
    /* resize: none; */
}
.bg-green{
    /* background-color: #cbefcb; */
    background-color: #90EE90 !important;
}
.md-width-model {
    background-color: rgba(0,0,0,0.7);
}
label{
    text-transform: capitalize !important;
}


.rate_nav.active, .rate_nav > ul> li.active, .rate_nav.active > a:hover {
    background:#35802f !important;
}
.rate_nav > ul> li a {
    color: #ffffff;
}
.rate_nav > ul> li > a:focus, .rate_nav > ul> li >a:hover {
    background:#35802f !important;
}

.diary_nav.active, .diary_nav > ul> li.active, .diary_nav.active > a:hover{
    background:#3279bb !important;
}
.diary_nav > ul> li a {
    color: #ffffff;
}
.diary_nav > ul> li > a:focus, .diary_nav > ul> li >a:hover {
    background:#3279bb !important;
}

.attorney_nav.active, .attorney_nav > ul> li.active {
    background:#D36207 !important;
}

.attorney_nav > ul> li > a:focus, .attorney_nav > ul> li >a:hover {
    background:#D36207 !important;
}


.firms_nav.active, .firms_nav > ul> li.active, .firms_nav.active > a:hover {
    background:#D36207 !important;
}
.firms_nav > ul> li a {
    color: #ffffff;
}
.firms_nav > ul> li > a:focus, .firms_nav > ul> li >a:hover {
    background:#D36207 !important;
}



.insurance_nav.active, .insurance_nav > ul> li.active, .insurance_nav.active > a:hover {
    background:#b3440c !important;
}
.insurance_nav > ul> li a {
    color: #ffffff;
}
.insurance_nav > ul> li > a:focus, .insurance_nav > ul> li >a:hover {
    background:#b3440c !important;
}



.representative_nav.active, .representative_nav > ul> li.active, .representative_nav.active > a:hover{
    background:#5f2e86 !important;
}
.representative_nav > ul> li a {
    color: #ffffff;
}
.representative_nav > ul> li > a:focus, .representative_nav > ul> li >a:hover {
    background:#5f2e86 !important;
}

.payroll_nav.active, .payroll_nav > ul> li.active, .payroll_nav.active > a:hover {
    background:#d64242 !important;
}
.payroll_nav > ul> li a {
    color: #ffffff;
}
.payroll_nav > ul> li > a:focus, .payroll_nav > ul> li >a:hover {
    background:#d64242 !important;
}

.invoice_nav.active, .invoice_nav > ul> li.active, .invoice_nav.active > a:hover {
    background:#888887 !important;
}
.invoice_nav > ul> li a {
    color: #ffffff;
}
.invoice_nav > ul> li > a:focus, .invoice_nav > ul> li >a:hover {
    background:#888887 !important;
}

.dash_nav.active, .dash_nav > ul> li.active, .dash_nav.active > a:hover {
    background:#D36207 !important;
}
.dash_nav > ul> li a {
    color: #ffffff;
}
.dash_nav > ul> li > a:focus, .dash_nav > ul> li >a:hover {
    background:#D36207 !important;
}

.ar_nav.active, .ar_nav > ul> li.active, .ar_nav.active > a:hover {
    background:#5f2e86 !important;
}
.ar_nav > ul> li a {
    color: #ffffff;
}
.ar_nav > ul> li > a:focus, .ar_nav > ul> li >a:hover {
    background:#5f2e86 !important;
}


.cash_nav.active, .cash_nav > ul> li.active, .cash_nav.active > a:hover {
    background:#D36207 !important;
}
.cash_nav > ul> li a {
    color: #ffffff;
}
.cash_nav > ul> li > a:focus, .cash_nav > ul> li >a:hover {
    background:#D36207 !important;
}

.adm_nav.active, .adm_nav > ul> li.active, .adm_nav.active > a:hover {
    background:#5f2e86 !important;
}
.adm_nav > ul> li a {
    color: #ffffff;
}
.adm_nav > ul> li > a:focus, .adm_nav > ul> li >a:hover {
    background:#5f2e86 !important;
}


.res_nav.active, .res_nav > ul> li.active, .res_nav.active > a:hover {
    background:#D36207 !important;
}
.res_nav > ul> li a {
    color: #ffffff;
}
.res_nav > ul> li > a:focus, .res_nav > ul> li >a:hover {
    background:#D36207 !important;
}

.rates_nav.active, .rates_nav > ul> li.active, .rates_nav.active > a:hover {
    background:#D36207 !important;
}
.rates_nav > ul> li a {
    color: #ffffff;
}
.rates_nav > ul> li > a:focus, .rates_nav > ul> li >a:hover {
    background:#D36207 !important;
}

.state_nav.active, .state_nav > ul> li.active, .state_nav.active > a:hover {
    background:#888887 !important;
}
.state_nav > ul> li a {
    color: #ffffff;
}
.state_nav > ul> li > a:focus, .state_nav > ul> li >a:hover {
    background:#888887 !important;
}

.reports_nav.active, .reports_nav > ul> li.active, .reports_nav.active > a:hover {
    background:#D36207 !important;
}
.reports_nav > ul> li a {
    color: #ffffff;
}
.reports_nav > ul> li > a:focus, .reports_nav > ul> li >a:hover {
    background:#D36207 !important;
}

.import_nav.active, .import_nav > ul> li.active, .import_nav.active > a:hover {
    background:#D36207 !important;
}
.import_nav > ul> li a {
    color: #ffffff;
}
.import_nav > ul> li > a:focus, .import_nav > ul> li >a:hover {
    background:#D36207 !important;
}

.serch_nav.active, .serch_nav > ul> li.active, .serch_nav.active > a:hover {
    background:#D36207 !important;
}
.serch_nav > ul> li a {
    color: #ffffff;
}
.serch_nav > ul> li > a:focus, .serch_nav > ul> li >a:hover {
    background:#D36207 !important;
}



.grandTotal {
    background: #3b4d5b;
    color: whitesmoke;
}


tags-input .tags .tag-item {
    background: linear-gradient(to bottom, #104a7b 0%, #104a7b 47%, #104a7b 100%) !important;
    color: white !important;
}

tags-input .autocomplete .suggestion-item.selected {
    color: #fff !important;
    background-color: #104a7b !important;
}


tags-input .autocomplete .suggestion-item{
    font-size: 14px !important;
}

tags-input .autocomplete .suggestion-item em {
    font-size: 14px !important;
    color: #000 !important;
    background-color: #fff !important;
}

tags-input .autocomplete .suggestion-item.selected em {
    font-size: 14px !important;
    color: #fff !important;
    background-color: #104a7b !important;
}

.env-sandbox{background: #cddc39 !important;}

.strikethrough {
    text-decoration: line-through;
}

.aging-table{
    background-color : #939393;
    font-size : 14px;
    font-weight: 600;
    color: #000;
}

.resource-tabs > ul > li {
    width: 14% !important;
}


.gray-bg{
    z-index: 1 !important;
}
.footer{
     z-index: -1;
}

.nav.metismenu::-webkit-scrollbar {width: 6px;}
.nav.metismenu::-webkit-scrollbar-track {box-shadow: inset 0 0 5px grey; border-radius: 10px;}
.nav.metismenu::-webkit-scrollbar-thumb {background: #9c9c9c; border-radius: 10px;}

.nav.metismenu {
    overflow: auto;
    display: block;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 220px;
}

.body-small.mini-navbar .nav.metismenu{
    position: absolute;
    overflow: inherit;
    width: auto;
}

.oursuit-popover .popover{
    max-width: 500px;
    width: 500px;
}

.oursuit-popover .popover-content {
    text-transform: lowercase;
}
.do-not-use{
    background: #ffc4cd;
    color: red;
}
.bu-clear{
    color: #fff;
    background-color: red;
    padding: 3px;
    border-radius: 5px;
    cursor: pointer;
}